<template>
	<div class="page flex-col">
		<div class="block_1 flex-row" :class="startX<=600?'wid600':''">
			<div class="box_1 flex-col">
				<div style="width: 100vw;" v-if="startX<=600">
					<head-top :type="3" :show="-1" :startX="startX"></head-top>
				</div>
				<div class="group_1 flex-col" v-else>
					<head-top :type="3" :startX="startX"></head-top>
				</div>
				<div class="group_3 flex-col"></div>
				<div class="group_4 flex-col">
					<div class="section1" @click.stop="toIndex(dataMain.banner.picture[0])">
						<img v-if="dataMain.banner.audio_type==0" :src="dataMain.banner.picture[0].abs_url" alt=""
							style="width: 100%;height:100%">
						<video v-else style="width: 100%;height:100%" :src="dataMain.banner.picture[0].abs_url"></video>
					</div>
					<!-- <el-carousel height="100%" class="section1">
						<el-carousel-item v-for="item in dataMain.banner.picture" :key="item.id" @click.stop="toIndex(item)">
							<img v-if="dataMain.banner.audio_type==0" :src="item.abs_url" alt=""
								style="width: 100%;height:100%">
							<video v-else style="width: 100%;height:100%" :src="item.abs_url"></video>
						</el-carousel-item>
					</el-carousel> -->
					<div class="group1 justify-between align-end">
						<div style="height: 100%;">
							<div class="block_2 flex-row">
								<div class="text-wrapper_1">
									<span class="text_10">N</span>
									<span class="text_11">EWS</span>
								</div>
							</div>
							<div class="text-wrapper_2 flex-row">
								<span class="text_12">新闻动态</span>
							</div>
						</div>

						<div class="block_3 flex-row">
							<span class="text_13">新闻动态</span>
							<img class="thumbnail_2" referrerpolicy="no-referrer"
								src="./assets/img/psbb2bs112qortzatjzsg6lknn0pixoqlead1b13aea-e8a7-40e0-97b0-3f911d83b8b0.png" />
							<span class="text_14">企业新闻</span>
							<img class="thumbnail_3" referrerpolicy="no-referrer"
								src="./assets/img/ps6l7dsq16o4gfx01hpogaxuoz97cvkr2ulc9100ebd-d6b7-4b11-bb71-e4357db52f65.png" />
							<span class="text_15">详情</span>
						</div>
					</div>
				</div>
				<span class="text_16">{{dataMain.title}}</span>
				<span class="text_17">
					{{dataMain.create_time}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;来源:&nbsp;{{dataMain.source}}
				</span>
				<div class="mainApp16" v-html="dataMain.content"></div>
				<span class="text_19 mainApp16">责任编辑：&nbsp;{{dataMain.author}}</span>
			</div>
		</div>
		<div class="" v-if="startX>600">
			<body-footer></body-footer>
		</div>
		<div class="" v-else>
			<body-footer1></body-footer1>
		</div>
	</div>
</template>
<script>
	import headTop from "../../components/headTop.vue";
	import bodyFooter from "../../components/bodyFooter.vue";
	import bodyFooter1 from "../../components/bodyFooter1.vue";
	import {
		newsDetail,
	} from "../../util/api";
	export default {
		data() {
			return {
				constants: {},
				startX:1200,
				news_id:'',
				dataMain:''
			};
		},
		components: {
			headTop,
			bodyFooter,
			bodyFooter1
		},
		mounted() {
			this.news_id=this.$route.params.news_id;
			this.getDate()
			this.startX = document.body.clientWidth;
			window.onresize = () => {
				return (() => {
					this.startX = document.body.clientWidth;
				})();
			};
		},
		watch: {
			lang(val, oldval) {
				if (val != oldval) {
					this.getDate()
					// console.log(this.startX, this.widNum, "屏幕宽度");
				}
			},
		},
		computed: {
			lang() {
				return this.$store.getters.getLang;
			},
		},
		methods: {
			getDate() {
				newsDetail({
					lang: this.lang,
					news_id:this.news_id
				}).then((res) => {
					console.log(res);
					this.dataMain = res.data
					// this.title = res.data.header_nav;
				});
			},
			toIndex(v) {
				if (v.target_rule==1){
					this.$router.push("/");
				}else if (v.target_rule==2){
					this.$router.push("/aboutUs");
				}else if (v.target_rule==3){
					let id = v.param
					this.$router.push("/news/"+id);
				}else if (v.target_rule==4){
					let news_id = v.param
					this.$router.push("/newDetail/"+news_id);
				}else if (v.target_rule==5){
					let id = v.param
					console.log(id);
					this.$router.push("/product/"+id);
				}else if (v.target_rule==6){
					this.$router.push("/companies");
				}else if (v.target_rule==7){
					this.$router.push("/Investor");
				}else if (v.target_rule==8){
					this.$router.push("/invite");
				}else if (v.target_rule==9){
					this.$router.push("/contact");
				}
			},
		},
	};
</script>
<style scoped lang="css" src="./assets/index.css" />
<style lang="scss" scoped>
	.wid600{
		width: 100%;
		
		.group_4{
			width: 100vw;
			min-width: 66.667rem;
			max-width: 200rem;
			height: 18vw;
			min-height: 12rem;
			max-height: 36.333rem;
			margin-top: 13.333rem;
			.group1 {
				width: 90vw;
				.block_2 {
					width: auto;
					height: auto;
					margin-top: 4.667rem;
					.text-wrapper_1{
						font-size: 6.667rem;
						line-height: 5.333rem;
					}
				}
				.text-wrapper_2{
					width: auto;
					height: auto;
					margin-top: 4rem;
					.text_12{
						width: auto;
						height: auto;
						font-size: 4rem;
						line-height: 5.333rem;
					}
				}
				.block_3 {
					width: auto;
					height: auto;
					margin: 0;
					margin-bottom: 2.667rem;
					.text_13{
						width: auto;
						height: auto;
						font-size: 3.333rem;
						line-height: 3.333rem;
					}
					.thumbnail_2{
						width: 1rem;
						height: 1.667rem;
						margin: 0 2rem;
					}
					.text_14{
						width: auto;
						height: auto;
						font-size: 3.333rem;
						line-height: 3.333rem;
						margin: 0;
					}
					.thumbnail_3{
						width: 1rem;
						height: 1.667rem;
						margin: 0 2rem;
					}
					.text_15{
						width: auto;
						height: auto;
						font-size: 3.333rem;
						line-height: 3.333rem;
						margin: 0;
					}
				}
			}
		}
		.text_16{
			width: auto;
			height: auto;
			font-size: 6rem;
			line-height: 8.3rem;
			margin-top: 12rem;
		}
		.text_17{
			width: auto;
			height: auto;
			font-size: 3.6rem;
			line-height: 4.3rem;
			margin-top: 5rem;
		}
		.image_3{
			width: 125rem;
			height: 77.667rem;
			margin-top: 10rem;
		}
		.text_18{
			width: 87vw;
			height: auto;
			margin-top: 6.667rem;
			font-size: 3.667rem;
			line-height: 7.333rem;
			white-space: normal;
		}
		.paragraph_1{
			width: 87vw;
			height: auto;
			margin-top: 6.667rem;
			font-size: 3.667rem;
			line-height: 7.333rem;
			white-space: normal;
		}
		.paragraph_2{
			width: 87vw;
			height: auto;
			margin-top: 6.667rem;
			font-size: 3.667rem;
			line-height: 7.333rem;
			white-space: normal;
		}
		.paragraph_3{
			width: 87vw;
			height: auto;
			margin-top: 6.667rem;
			font-size: 3.667rem;
			line-height: 7.333rem;
			white-space: normal;
		}
		.text-group_1{
			width: 87vw;
			height: auto;
			margin-top: 6.667rem;
			.paragraph_4{
				font-size: 3.667rem;
				line-height: 7.333rem;
				white-space: normal;
			}
			.paragraph_5{
				margin-top: 6.667rem;
				font-size: 3.667rem;
				line-height: 7.333rem;
				white-space: normal;
			}
		}
		.paragraph_6{
			width: 87vw;
			height: auto;
			margin-top: 6.667rem;
			font-size: 3.667rem;
			line-height: 7.333rem;
			white-space: normal;
		}
		.text_19{
			width: 87vw;
			height: auto;
			margin-top: 6.667rem;
			font-size: 3.667rem;
			line-height: 7.333rem;
			white-space: normal;
			margin: 7rem 0 10.333rem 0;
		}
	}
</style>
